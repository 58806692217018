import { canAccessWorkspace } from '@/contexts/workspaceContext';
import { RoleType } from '@/lib/models';
import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';
import { GroupAccess } from '@/pages/workgroup/tab/networkAccess/hook';

export type Region = {
  name: string;
  description: string;
};

export type TGVersion = {
  name: string;
  supportS3: false;
};

export type WorkSpaceType = {
  typeName: string;
  storage: string;
  cpu: string;
  memory: string;
  partition: number;

  // the following fields is calculate by using pricing api in useQueryWorkspaceMeta()
  cost_per_hour: number;
  cost_per_hour_with_ha: number;
};

export type WorkSpaceMeta = {
  regions: Region[];
  tgVersions: TGVersion[];
  workspaceTypes: WorkSpaceType[];
};

export type WorkspacePrice = {
  workspace_type: string;
  platform: string;
  region: string;
  price_per_hour: number;
  ha: 'disabled' | 'enabled';
  cloud_provider: 'BYOC' | 'TGCloud';
};

export type AddonPrice = {
  type: 'CoPilot' | 'Insights';
  workspace_type: string;
  platform: string;
  region: string;
  price_per_hour: number;
};

export type StoragePrice = {
  platform: string;
  region: string;
  price_per_month: number;
};

export type Pricing = {
  workspace_pricing: WorkspacePrice[];
  addon_pricing: AddonPrice[];
  data_storage_pricing: StoragePrice[];
};

export type WorkSpaceOrgQuota = {
  tier: string;
  max_workspace_type: string;
  workspace_manual_backup_count_limit: number;

  rw_workspace_count_limit: number;
  ro_workspace_count_limit: number;
  rw_workspace_count_usage: number;
  ro_workspace_count_usage: number;
};

export type FeatureFlags = { feature_name: string; enabled: boolean }[] | null;

export type FolderStatistics = {
  billed_size: number;
};

export type DatabaseT = {
  database_id: string;
  name: string;
  workgroup_id: string;
  tg_version: string;
  create_time: string;
  folder_statistics?: FolderStatistics;
};

export type WorkspaceStatus =
  | 'Active'
  | 'Idle'
  | 'Stopped'
  | 'Error'
  | 'Pending'
  | 'Updating'
  | 'Upgrading'
  | 'Resizing'
  | 'Stopping'
  | 'Resuming'
  | 'Terminating'
  | 'Pausing'
  | 'Degraded';

export type WorkspaceRefreshStatus = 'Init' | 'Exporting' | 'Importing' | 'Failed' | 'Success' | 'Interrupted' | '';
export type ConditionType =
  | 'Normal'
  | 'InitializeRoll'
  | 'InitializePost'
  | 'UpdateRoll'
  | 'UpgradeRoll'
  | 'UpgradePost'
  | 'ExpandRoll'
  | 'ExpandPost'
  | 'ExpandRollBack'
  | 'ShrinkPre'
  | 'ShrinkRoll'
  | 'ConfigUpdate'
  | 'PauseRoll'
  | 'Paused'
  | 'ResumeRoll'
  | 'HAUpdate'
  | 'Degraded'
  | 'NotReady';

export type WorkspaceT = {
  name: string;
  status: WorkspaceStatus;
  condition_type: ConditionType;
  workspace_id: string;
  workgroup_id: string;
  database_id: string;
  tg_version: string;
  created_at: string;
  creator: string;
  last_modified_time: string;
  workspace_type: WorkSpaceType;
  is_rw: boolean;
  nginx_host: string;
  role: RoleType;
  enable_auto_start: boolean;
  enable_ha: boolean;
  enable_copilot: boolean;
  copilot_llm_provider_config_id: string;
  auto_stop_minutes: number;
  snapshot_time: string;
  refresh_status: WorkspaceRefreshStatus;
  addons?: WorkspaceAddons[];
  graph_topology_size_bytes?: number;
  vertex_count?: number;
  edge_count?: number;

  // calculated fields, see workspaceContext.tsx
  // 1. if in group detail, use `useAccess()` hook
  // 2. if use workspace from workspace context,, use `canAccess` field
  canAccess: boolean;

  // set up in
  // 1 workspaceContext.tsx
  // 2 workgroup/api.ts
  workgroup_name: string; // from workgroup
  cloud_provider_id: string; // from workgroup
  region: string; // from workgroup
  platform: string; // from workgroup
  database_name: string; // from tg_database
};

export function calculatePropsForWorkspace(workspace: WorkspaceT, workgroup: WorkGroupT, groupsAccess?: GroupAccess) {
  workspace.workgroup_name = workgroup.name;
  workspace.cloud_provider_id = workgroup.cloud_provider_id;
  workspace.platform = workgroup.platform;
  workspace.region = workgroup.region;
  workspace.database_name =
    workgroup.tg_databases.find((db) => (db.database_id === workspace.database_id ? db : undefined))?.name || '';
  workspace.canAccess = canAccessWorkspace(workgroup.enable_allow_list, workgroup.workgroup_id, groupsAccess);
  return workspace;
}

export type WorkGroupT = {
  workgroup_id: string;
  name: string;
  org_id: string;
  platform: string;
  region: string;
  tg_databases: DatabaseT[];
  workspaces: WorkspaceT[];
  creator: string;
  role: RoleType;
  enable_allow_list: boolean;
  cloud_provider_id: string;
};

export type CreateWorkGroupRequest = {
  name: string;
  region: string;
  platform: string;
  cloud_provider_id: string;
};

export type CreateWorkSpaceRequest = {
  workspace_type_name: string;
  tg_database_id: string;
  tg_version: string;
  cloud_provider_id: string;

  name: string;
  size: number;
  is_rw: boolean;
  enable_auto_start: boolean;
  auto_stop_minutes: number;
  enable_copilot?: boolean;
  copilot_llm_provider_config_id?: string;

  workgroup_id: string;
  addons: {
    addons_id: string;
    enable: boolean;
    enable_configuration: Record<string, undefined | string | number>;
  }[];

  enable_ha: boolean;
  solution_install_request?: {
    name: string;
    solution_catalog_id: string;
    data_source_origin: string;
  };
};

export type UpdateWorkspaceRequest = {
  workspace_type_name?: string;
  enable_auto_start?: boolean;
  auto_stop_minutes?: number;
  enable_copilot?: boolean;
  workspace_name?: string;
  addons?: {
    addons_id: string;
    enable: boolean;
    enable_configuration: Record<string, undefined | string | number>;
  }[];
  enable_ha?: boolean;
};

export type CreateDatabaseRequest = {
  name: string;
  tg_version: string;
  workgroup_id: string;
};

export type BackupSchedule = {
  schedule: string;
  pause: boolean;
};

export type WorkspaceAddons = {
  enable: boolean;
  addons_id: string;
  // workspace_id: string;
  enable_configuration: Record<string, string>;
};

export function isStatusActive(status: WorkspaceStatus) {
  // treat degraded the same as active
  return status === 'Active' || status === 'Degraded';
}

// when in Degraded status
// 1. can not install query (disable install solution/generate data profile)
// 2. can not load data (disable load data)
export function isStatusDegraded(status: WorkspaceStatus) {
  return status === 'Degraded';
}

// Paused + Auto Start On
export function isStatusIdle(status: WorkspaceStatus) {
  return status === 'Idle';
}

// Paused + Auto Start off
export function isStatusPaused(status: WorkspaceStatus) {
  return status === 'Stopped';
}

export function isStatusError(status: WorkspaceStatus) {
  return status === 'Error';
}

export function isStatusInitializing(status: WorkspaceStatus) {
  return status === 'Pending';
}

export function isStatusPending(status: WorkspaceStatus) {
  return (
    status === 'Pending' ||
    status === 'Updating' ||
    status === 'Upgrading' ||
    status === 'Resizing' ||
    status === 'Stopping' ||
    status === 'Resuming' ||
    status === 'Terminating' ||
    status === 'Pausing'
  );
}

export function isRefreshIng(refresh_status: WorkspaceRefreshStatus) {
  return (
    refresh_status === 'Init' ||
    refresh_status === 'Exporting' ||
    refresh_status === 'Importing' ||
    refresh_status === 'Interrupted'
  );
}

export function canRefresh(refresh_status: WorkspaceRefreshStatus) {
  return !refresh_status || refresh_status === 'Success' || refresh_status === 'Failed';
}

// check quota for creating workspace

export function canCreateWorkspace(orgQuota: WorkSpaceOrgQuota) {
  return canCreateRWWorkspace(orgQuota) || canCreateROWorkspace(orgQuota);
}

export function canCreateWorkspaceWithNewGroup(orgQuota: WorkSpaceOrgQuota) {
  return canCreateWorkspace(orgQuota);
}

export function canCreateRWWorkspace(orgQuota: WorkSpaceOrgQuota) {
  return orgQuota.rw_workspace_count_limit > orgQuota.rw_workspace_count_usage;
}

export function canCreateROWorkspace(orgQuota: WorkSpaceOrgQuota) {
  return orgQuota.ro_workspace_count_limit > orgQuota.ro_workspace_count_usage;
}

export function workspaceQuotaError(orgQuota: WorkSpaceOrgQuota) {
  return `Your organization has reached its workspace limits: a maximum of ${
    orgQuota.rw_workspace_count_limit
  } read-write ${pluralize(orgQuota.rw_workspace_count_limit, 'workspace', 'workspaces')}, or ${
    orgQuota.ro_workspace_count_limit
  } read-only ${pluralize(
    orgQuota.ro_workspace_count_limit,
    'workspace',
    'workspaces'
  )}. To create a new workspace, please delete an existing one or contact support for help.`;
}

export function workspaceQuotaRWError(orgQuota: WorkSpaceOrgQuota) {
  return `Your organization has reached the limit of ${orgQuota.rw_workspace_count_limit} read-write ${pluralize(
    orgQuota.rw_workspace_count_limit,
    'workspace',
    'workspaces'
  )}. To create a new read-write workspace, please delete an existing one or contact support for help.`;
}

export function workspaceQuotaROError(orgQuota: WorkSpaceOrgQuota) {
  return `Your organization has reached the limit of ${orgQuota.ro_workspace_count_limit} read-only ${pluralize(
    orgQuota.ro_workspace_count_limit,
    'workspace',
    'workspaces'
  )}. To create a new read-only workspace, please delete an existing one or contact support for help.`;
}

export function workspaceQuotaBackupError(orgQuota: WorkSpaceOrgQuota) {
  return `Your workspace has reached the limit of ${orgQuota.workspace_manual_backup_count_limit} ${pluralize(
    orgQuota.workspace_manual_backup_count_limit,
    'backup',
    'backups'
  )}. To create a new backup, please delete an existing one or contact support for help.`;
}

function pluralize(count = 0, singular = '', plural = '') {
  return count === 1 ? singular : plural;
}

export function getWorkspacePrice(pricing: Pricing, workspace: WorkspaceT, cps: CloudProviderT[]) {
  const { workspace_type, enable_ha, region, platform, cloud_provider_id } = workspace;
  const cloud_provider = cps.find((cp) => cp.id === cloud_provider_id)?.type === 'public' ? 'TGCloud' : 'BYOC';
  return getWorkspaceTypePrice(pricing, workspace_type.typeName, enable_ha, platform, region, cloud_provider);
}

//  [workspace_type, ha, region, platform, cloud_provider]
export function getWorkspaceTypePrice(
  pricing: Pricing,
  workspace_type: string,
  ha: boolean,
  platform: string,
  region: string,
  cloud_provider: 'BYOC' | 'TGCloud'
) {
  const workspacePrice = pricing.workspace_pricing.find(
    (price) =>
      price.workspace_type === workspace_type &&
      price.platform === platform.toLocaleLowerCase() &&
      price.region === region &&
      price.ha === (ha ? 'enabled' : 'disabled') &&
      price.cloud_provider === cloud_provider
  );
  if (!workspacePrice) {
    console.log(
      `missing price plan for workspace: [${workspace_type}, ${
        ha ? `ha(enabled)` : `ha(disabled)`
      }, ${region}, ${platform}, ${cloud_provider}]`
    );
  }

  if (workspacePrice) {
    return workspacePrice.price_per_hour / 100;
  }
  return workspacePrice || 0;
}

// [addOnType, workspace_type, region, platform]
// Add on price is based on the original price, so it doesn't have to be dependent on the Cloud Provider
export function getAddonPrice(
  pricing: Pricing,
  addOnType: 'CoPilot' | 'Insights',
  workspace_type: string,
  platform: string,
  region: string
) {
  const addPrice = pricing.addon_pricing.find(
    (price) =>
      price.type === addOnType &&
      price.workspace_type === workspace_type &&
      price.platform === platform.toLocaleLowerCase() &&
      price.region === region
  );

  if (!addPrice) {
    console.log(`missing price plan for addon: [${addOnType}, ${workspace_type}, ${region}, ${platform}]`);
  }

  if (addPrice) {
    return addPrice.price_per_hour / 100;
  }
  return 0;
}

// [platform, region]
export function getStoragePrice(pricing: Pricing, platform: string, region: string) {
  const storagePrice = pricing.data_storage_pricing.find(
    (price) => price.platform === platform.toLocaleLowerCase() && price.region === region
  );

  if (!storagePrice) {
    console.log(`missing price plan for storage: [${region}, ${platform}]`);
  }

  if (storagePrice) {
    return storagePrice.price_per_month / 100;
  }
  return 0;
}
