import { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  FeatureFlags,
  Pricing,
  isStatusActive,
  WorkGroupT,
  WorkspaceT,
  WorkSpaceOrgQuota,
  calculatePropsForWorkspace,
} from '@/pages/workgroup/type';
import { axiosCluster } from '@/lib/network';
import { useLocalStorageState } from 'ahooks';
import { getAxiosInstance, simpleAuth, UserInfo } from '@tigergraph/tools-models';
import { useQuery } from 'react-query';
import DBUser from '@/contexts/dbUser';
import {
  useCloudProviders,
  useQueryGetFeatureFlag,
  useQueryGetGroups,
  useQueryGetOrgQuota,
  useQueryGetPricing,
} from '@/pages/workgroup/hook';
import { useSearchParams } from 'react-router-dom';
import { getInstanceAxios } from '@tigergraph/tools-ui/insights/api/ajax';
import { setBaseURL } from '@tigergraph/tools-ui/insights/config/config-interface';
import axios from 'axios';
import { GroupAccess, useQueryGroupsAccess } from '@/pages/workgroup/tab/networkAccess/hook';
import commandExecutor from '@/pages/editor/result/CommandExecutor';
import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';
import { useOrgContext } from '@/contexts/orgContext';
import { schemaCache } from '@/pages/editor/GSQL/schemaCache';

export const GLOBAL_VIEW = '1';
export const ID_TOKEN_KEY = 'id_token';

interface WorkspaceContextValue {
  workspaces: WorkspaceT[];
  groupsAccess?: GroupAccess;
  isLoading: boolean;
  currentWorkspace?: WorkspaceT;
  changeToRWWorkspace(): void;
  graphNames: string[];
  setGraphNames(graphs: string[]): void;
  currentGraph: string;
  setCurrentGraph(name?: string): void;
  refetchSimpleAuth: () => void;
  dbUser: DBUser | null;
  setDBUser: (dbUser: DBUser) => void;
  price?: Pricing;
  cps: CloudProviderT[];
  featureFlags: FeatureFlags;
  orgQuota?: WorkSpaceOrgQuota;
}

const workspaceContext = createContext<WorkspaceContextValue>({} as WorkspaceContextValue);

const useWorkspaceContext = () => {
  const context = useContext(workspaceContext);
  return context;
};

const WorkspaceProvider: FC<PropsWithChildren> = (props) => {
  const [currentWorkspace, setCurrentWorkspace] = useState<WorkspaceT | undefined>(undefined);
  const [graphNames, setGraphNames] = useState<string[]>([]);
  const [currentGraph, setCurrentGraph] = useLocalStorageState<string>('current-graph-gshell', {
    defaultValue: 'MyGraph',
  }) as any;
  const [dbUser, setDBUser] = useState<DBUser | null>(null);

  const { isLoading, data } = useQueryGetGroups();

  const groups = useMemo(() => {
    return data?.Result || [];
  }, [data]);

  const { data: groupsAccess } = useQueryGroupsAccess(
    groups.map((g) => g.workgroup_id),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 15 * 3000,
    }
  );

  const { data: priceResult } = useQueryGetPricing();
  const price = priceResult?.Result;

  const { cps } = useCloudProviders();

  const { currentOrg } = useOrgContext();

  // org quota
  const orgQuotaQuery = useQueryGetOrgQuota();
  const orgQuota = orgQuotaQuery.data?.Result;

  // feature flag
  const { data: featureFlagData } = useQueryGetFeatureFlag();
  let featureFlags: FeatureFlags = null;
  if (featureFlagData?.Result) {
    for (let flags of featureFlagData.Result) {
      if (flags.org_id === currentOrg?.org_id) {
        featureFlags = flags.feature_flag;
        break;
      }
    }
  }

  // after change workspace
  // 1 reset graph list
  // 2 reset current graph
  const setCurrentWorkspaceResetGraphs = useCallback(
    (workspace?: WorkspaceT) => {
      setCurrentWorkspace(workspace);
      setGraphNames([]);
      setCurrentGraph('');

      if (workspace) {
        schemaCache.setWorkSpaceInfo(workspace, []);
      }
    },
    [setCurrentGraph]
  );

  const getWorkspaces = useCallback(
    (workgroups: WorkGroupT[] | undefined) => {
      if (!workgroups) {
        return [];
      }
      return workgroups
        .map((group) =>
          group.workspaces.map((ws) => {
            return calculatePropsForWorkspace(ws, group, groupsAccess);
          })
        )
        .flat();
    },
    [groupsAccess]
  );

  // 1 assume user select workspace through WorkspaceSector
  // 2 keep the source of truth at WorkspaceContext
  // 3 need to sync from search params when set the first workspace
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const workspaces = getWorkspaces(groups);

    const urlWorkspaceId = searchParams.get('workspace_id');
    const urlWorkspace = workspaces.find((workspace) => workspace.workspace_id === urlWorkspaceId);
    const workspaceInData = workspaces.find((workspace) => workspace.workspace_id === currentWorkspace?.workspace_id);

    // prefer urlWorkspace if it is in the list
    const workspace = urlWorkspace || workspaceInData;

    if (workspace && isStatusActive(workspace.status)) {
      // update update current workspace if it is not the same or name is changed
      if (
        currentWorkspace?.workspace_id !== workspace.workspace_id ||
        currentWorkspace.name !== workspace.name ||
        currentWorkspace.canAccess != workspace.canAccess
      ) {
        setCurrentWorkspaceResetGraphs(workspace);
      }
    } else {
      // 1 if current workspace is not in the list
      // 2 if current workspace is not active
      //   we just reset the current workspace to the first active workspace
      // if current workspace is not in the list (or current workspace is undefined)
      // also need to wait until we get all group access information
      if (workspaces.length > 0 && groupsAccess) {
        // try to set the first active workspace
        const activeWorkspaces = workspaces.filter(
          (workspace) => isStatusActive(workspace.status) && workspace.canAccess
        );
        if (activeWorkspaces.length > 0) {
          setCurrentWorkspaceResetGraphs(activeWorkspaces[0]);
        } else {
          // reset the current workspace to undefined
          setCurrentWorkspaceResetGraphs(undefined);
        }
      } else {
        // reset the current workspace to undefined
        setCurrentWorkspaceResetGraphs(undefined);
      }
    }
  }, [groups, searchParams, getWorkspaces, setCurrentWorkspaceResetGraphs, currentWorkspace, groupsAccess]);

  // effect without useEffect
  // We want to update the axios baseURL when the currentWorkspace changes
  if (currentWorkspace) {
    const workspaceURL = `https://${currentWorkspace.nginx_host}`;

    // @tigergraph/tools-ui
    if (getInstanceAxios()?.defaults?.baseURL !== workspaceURL) {
      let idToken = sessionStorage.getItem(ID_TOKEN_KEY)!;
      setBaseURL(axios.create({}), workspaceURL, {
        idToken,
      });
    }

    // @tigergraph/tools-models
    if (getAxiosInstance().defaults?.baseURL !== workspaceURL) {
      getAxiosInstance().defaults.baseURL = workspaceURL;
      getAxiosInstance().defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`;
    }

    if (axiosCluster.defaults.baseURL !== workspaceURL) {
      axiosCluster.defaults.baseURL = workspaceURL;
    }
  }

  const simpleAuthQ = useQuery(
    ['simpleAuth', currentWorkspace],
    async () => {
      const res = await simpleAuth({
        baseURL: `https://${currentWorkspace?.nginx_host}`,
        version: currentWorkspace?.tg_version,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem(ID_TOKEN_KEY)}`,
        },
      });
      return res.data;
    },
    {
      enabled: currentWorkspace !== undefined,
      onSuccess: (data) => {
        const info = data.results;
        if (info) {
          setDBUser(new DBUser(info as UserInfo));
          const names = Object.keys(info.roles || {});
          setGraphNames(names);
          schemaCache.setWorkSpaceInfo(currentWorkspace, names);

          const nonDefaultGraphs = names.filter((d) => d !== 'MyGraph' && d !== GLOBAL_VIEW);
          const nonGlobalGraphs = names.filter((d) => d !== GLOBAL_VIEW);

          if (names.includes(currentGraph)) {
            // keep graph not changed
          } else if (nonDefaultGraphs.length > 0) {
            setCurrentGraph(nonDefaultGraphs[0]);
          } else if (nonGlobalGraphs.length > 0) {
            setCurrentGraph(nonGlobalGraphs[0]);
          } else {
            setCurrentGraph(GLOBAL_VIEW);
          }
          commandExecutor.clearCookie();
        }
      },
    }
  );

  const workspaces = useMemo(() => {
    return getWorkspaces(groups);
  }, [groups, getWorkspaces]);

  // change to active RW workspace
  const changeToRWWorkspace = useCallback(() => {
    const rwWorkspaces = workspaces.filter((workspace) => workspace.is_rw && isStatusActive(workspace.status));
    if (rwWorkspaces.length > 0) {
      setCurrentWorkspaceResetGraphs(rwWorkspaces[0]);
    } else {
      setCurrentWorkspaceResetGraphs(undefined);
    }
  }, [workspaces, setCurrentWorkspaceResetGraphs]);

  const WorkspaceContextValue: WorkspaceContextValue = {
    workspaces: workspaces,
    groupsAccess,
    isLoading,
    currentWorkspace,
    changeToRWWorkspace,
    currentGraph,
    setCurrentGraph,
    graphNames,
    setGraphNames,
    refetchSimpleAuth: simpleAuthQ.refetch,
    dbUser,
    setDBUser,
    price,
    cps,
    featureFlags,
    orgQuota,
  };

  return <workspaceContext.Provider value={WorkspaceContextValue} {...props} />;
};

export function canAccessWorkspace(enable_allow_list: boolean, workgroup_id: string, groupsAccess?: GroupAccess) {
  if (!enable_allow_list) {
    return true;
  }
  if (!groupsAccess) {
    return true;
  }

  if (groupsAccess.is_allowed.has(workgroup_id)) {
    return groupsAccess.is_allowed.get(workgroup_id)!;
  }

  // default is true
  return true;
}

export { WorkspaceProvider, useWorkspaceContext };
